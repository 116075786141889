import {
  Box,
  Button,
  chakra,
  Icon,
  IconButton,
  IconButtonProps,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useClipboard,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import * as React from "react"
import { RiShareBoxFill } from "react-icons/ri"
import { IconType } from "react-icons"
import {
  FaEnvelope,
  FaFacebook,
  FaLink,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa"

interface SocialSharingProps {
  buttonProps?: IconButtonProps
  layout?: `column` | `row`
  mediaUrl?: string
  title?: string
  url: string
}

interface SocialLinkProps {
  bg: string
  icon: IconType
  mediaUrl?: string
  name: string
  title?: string
  url: string
}

function generatePinterestHref(
  url: string,
  mediaUrl: string,
  title: string
): string {
  let href = `https://www.pinterest.com/pin/create/button`
  href += `?url=${url}`
  href += `&media=${mediaUrl}`
  href += (title && `&description=${encodeURIComponent(title)}`) || ``
  return href
}

const commonProps = {
  alignItems: `center`,
  color: `white`,
  d: `flex`,
  h: `32px`,
  px: `4px`,
  py: `15px`,
  roundedLeft: `base`,
  shadow: `md`,
  transitionBehavior: `ease-in-out`,
  transitionDuration: `0.3s`,
  _hover: { transform: `translateX(-86px)` },
}

const iconProps = {
  boxSize: `20px`,
  mx: `6px`,
  mr: `12px`,
  w: `32px`,
}

const spanProps = {
  fontWeight: `semibold`,
  w: `112px`,
}

function SocialLink({ bg, icon, name, url }: SocialLinkProps): JSX.Element {
  return (
    <Link
      {...commonProps}
      bg={bg}
      flexDirection="row"
      href={url}
      rel="nofollow noreferrer"
      target="_blank"
    >
      <Icon as={icon} {...iconProps} />
      <chakra.span {...spanProps}>{name}</chakra.span>
    </Link>
  )
}

function SocialSharing({
  layout = `column`,
  mediaUrl,
  title,
  url,
}: SocialSharingProps): JSX.Element {
  const { onCopy } = useClipboard(url)
  const showToast = useToast()

  return (
    <Box pos="relative" w="170px" zIndex="sticky">
      <Stack
        direction={layout}
        h="128px"
        left="0"
        pos="absolute"
        transform="translateX(130px)"
        top="0"
        w="170px"
      >
        <Text fontSize="xs" fontWeight="bold" mb={-1}>
          Share:
        </Text>
        <SocialLink
          bg="facebook.500"
          icon={FaFacebook}
          name="Facebook"
          url={`https://facebook.com/sharer/sharer.php?u=${url}`}
        />
        <SocialLink
          bg="twitter.500"
          icon={FaTwitter}
          name="Twitter"
          url={`https://twitter.com/intent/tweet/?url=${url}`}
        />
        {mediaUrl && title && (
          <SocialLink
            bg="rgb(230, 0, 35)"
            icon={FaPinterest}
            name="Pinterest"
            url={generatePinterestHref(url, mediaUrl, title)}
          />
        )}
        <SocialLink
          bg="purple.500"
          icon={FaEnvelope}
          name="Email"
          url={`mailto:${url}`}
        />
        <Button
          bg="green.500"
          {...commonProps}
          flexDirection="row"
          onClick={() => {
            onCopy()
            showToast({
              duration: 2000,
              isClosable: true,
              status: "success",
              title: "Link copied!",
            })
          }}
        >
          <Icon as={FaLink} {...iconProps} ml="-4px" />
          <chakra.span {...spanProps} textAlign="left">
            Copy Link
          </chakra.span>
        </Button>
      </Stack>
    </Box>
  )
}

export default SocialSharing

function MobileShareMenu({
  buttonProps,
  mediaUrl,
  title,
  url,
}: SocialSharingProps): JSX.Element {
  const { onCopy } = useClipboard(url)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const showToast = useToast()

  const iconButtonProps = Object.assign(
    {
      "aria-label": `Share on social media`,
      border: `2px`,
      borderColor: `white`,
      bottom: `50px`,
      colorScheme: `red`,
      d: { lg: `none` },
      pos: `fixed`,
      right: `10px`,
      rounded: `full`,
      shadow: `lg`,
      size: `lg`,
      zIndex: `docked`,
    },
    buttonProps
  )

  return (
    <>
      <IconButton
        {...iconButtonProps}
        icon={<Icon as={RiShareBoxFill} boxSize="25px" />}
        onClick={onOpen}
      />

      <Modal closeOnOverlayClick isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton size="lg" />
          <ModalBody py={12}>
            <Stack
              alignItems="center"
              direction="column"
              justifyContent="center"
              spacing={6}
            >
              <Text fontSize="2xl" fontWeight="extrabold">
                Share:
              </Text>
              <Button
                as={Link}
                colorScheme="facebook"
                href={`https://facebook.com/sharer/sharer.php?u=${url}`}
                leftIcon={<FaFacebook />}
                shadow="lg"
                size="lg"
                w="200px"
              >
                Facebook
              </Button>
              <Button
                as={Link}
                colorScheme="twitter"
                href={`https://twitter.com/intent/tweet/?url=${url}`}
                leftIcon={<FaTwitter />}
                shadow="lg"
                size="lg"
                w="200px"
              >
                Twitter
              </Button>
              {mediaUrl && title && (
                <Button
                  as={Link}
                  bg="rgb(230, 0, 35)"
                  color="white"
                  href={generatePinterestHref(url, mediaUrl, title)}
                  leftIcon={<FaPinterest />}
                  shadow="lg"
                  size="lg"
                  w="200px"
                  _hover={{ bg: `rgb(210, 0, 35)` }}
                >
                  Pinterest
                </Button>
              )}
              <Button
                as={Link}
                colorScheme="purple"
                href={`mailto:${url}`}
                leftIcon={<FaEnvelope />}
                shadow="lg"
                size="lg"
                w="200px"
              >
                Email
              </Button>
              <Button
                colorScheme="green"
                leftIcon={<FaLink />}
                onClick={() => {
                  onCopy()
                  onClose()
                  showToast({
                    duration: 2000,
                    isClosable: true,
                    status: "success",
                    title: "Link copied!",
                  })
                }}
                shadow="lg"
                size="lg"
                w="200px"
                _hover={{ bg: `green.600`, textDecoration: `underline` }}
              >
                Copy Link
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export { MobileShareMenu, SocialSharingProps, generatePinterestHref }
